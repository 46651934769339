<template>
  <card class="p-3 col-lg-8 bg-secondary">
    <h1 class="page-header mb-lg-3 card-title">Reset your password</h1>
    <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
      <form class="needs-validation" @submit.prevent="validate().then(submit)">
        <div class="row d-flex justify-content-center">
          <div class="mx-3 w-100">
            <b-row class="mb-4">
              <b-col lg="12">
                <base-input
                    v-model="email"
                    alternative
                    group
                    name="email"
                    placeholder="Email Address"
                    rules="required|email"
                    type="email"
                    formGroupClasses="mb-0"
                ></base-input>
                <p v-if="!confirmCode" class="small-text mb-0 text-right">
                  <a class="gray-600-color" href="#!" @click="confirmCode = !confirmCode">I have a code from my
                    email</a>
                </p>
              </b-col>
              <b-col lg="12">
                <base-input
                    v-if="confirmCode"
                    v-model="code"
                    alternative
                    group
                    name="code"
                    placeholder="Code from your email"
                    rules="required"
                    type="text"
                    formGroupClasses="mb-0"
                ></base-input>
              </b-col>
              <b-col lg="12">
                <base-input
                    v-if="passwordWithCode"
                    ref="password"
                    v-model="password"
                    :rules="{ required: true, regex: passwordRegex }"
                    :type="show_password ? 'text' : 'password'"
                    alternative
                    autocomplete="off"
                    group
                    name="password"
                    placeholder="Password"
                    @input="checkPassword"
                    formGroupClasses="mb-0"
                >
                  <template slot="append">
                    <i :class="show_password === true ? 'fas fa-eye-slash' : 'fas fa-eye'"
                       aria-hidden="true"
                       class="eye-icon" @click="show_password = !show_password"></i>
                  </template>
                </base-input>
                <transition name="slide">
                  <div v-show="show_helper" class="password-helper gray-600-color small-text">
                    <b-row>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_twelve_characters }">
                          <input disabled type="checkbox" v-bind:checked="contains_twelve_characters"/>
                          <span>12 Characters</span>
                        </li>
                      </b-col>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_number }">
                          <input disabled type="checkbox" v-bind:checked="contains_number"/>
                          <span>Includes Number</span>
                        </li>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_uppercase }">
                          <input disabled type="checkbox" v-bind:checked="contains_uppercase"/>
                          <span>Includes Uppercase</span>
                        </li>
                      </b-col>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_special_character }">
                          <input disabled type="checkbox" v-bind:checked="contains_special_character"/>
                          <span style="margin-left: 5px">Includes Special Character</span>
                        </li>
                      </b-col>
                    </b-row>
                  </div>
                </transition>
              </b-col>
            </b-row>
            <div class="layout-button">
              <div class="form-common-errors small-text mb-2">
                {{ resetPasswordFormErrors }}
              </div>
              <button
                  :disabled="invalid"
                  class="btn btn-outline btn-primary btn-block font-weight-bold"
                  type="submit"
              >Reset password
              </button>
            </div>
            <div class="mt-2rem text-center">
              <p class="font-weight-bold">
                <router-link :to="{ name: 'Login' }">Return to Sign in</router-link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>
<script>
import MicrosoftLogo from '/src/components/App/UI/MicrosoftLogo.vue';
import {RESET_PASSWORD_SEND_EMAIL_REQUEST, RESET_PASSWORD_USING_CODE_REQUEST} from '@/store/storeActions';
import {mapActions} from 'vuex';

export default {
  components: {
    MicrosoftLogo,
  },
  data() {
    return {
      email: '',
      captchaToken: null,
      code: '',
      password: '',
      microsoftSign: false,
      validator: null,
      confirmCode: false,
      show_password: false,
      show_helper: false,
      password_length: 0,
      contains_twelve_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      // eslint-disable-next-line
      passwordRegex: /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{12,}$/,
    };
  },
  methods: {
    ...mapActions([
      RESET_PASSWORD_SEND_EMAIL_REQUEST,
      RESET_PASSWORD_USING_CODE_REQUEST,
    ]),
    async submit() {
      const data = this.getPayloadData();
      data['captchaToken'] = await this.executeRecaptcha();
      if (this.passwordWithCode) {
        await this.RESET_PASSWORD_USING_CODE_REQUEST(data);
      } else {
        this.confirmCode = await this.RESET_PASSWORD_SEND_EMAIL_REQUEST(data);
      }
      this.clearData();
    },
    executeRecaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(process.env.VUE_APP_GOOGLE_CAPTCHA_KEY, {action: 'reset_password'}).
              then(resolve).
              catch(reject);
        });
      });
    },
    clearData() {
      this.email = '';
      this.code = '';
      this.password = '';
      this.validator.reset();
    },
    getPayloadData() {
      return this.passwordWithCode ?
          {
            email: this.email,
            code: this.code,
            newPassword: this.password,
          } :
          {
            email: this.email,
          };
    },
    checkPassword() {
      this.password_length = this.password.length;
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      this.contains_twelve_characters = this.password_length >= 12;
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      this.valid_password = this.contains_twelve_characters === true &&
          this.contains_special_character === true &&
          this.contains_uppercase === true &&
          this.contains_number === true;
      this.show_helper = true;
    },
  },
  computed: {
    getFormErrors: function() {
      const errors = [];
      if (this.validator) {
        Object.entries(this.validator.errors).forEach(([key, value]) => {
          if (key !== 'password' && value.length > 0) {
            errors.push(...value);
          }
        });
      }
      return errors;
    },
    resetPasswordFormErrors: function() {
      let errMessage = '';
      const errors = this.getFormErrors;
      if (errors.length > 0) {
        errMessage = 'Please fill correctly required fields';
      }
      return errMessage;
    },
    passwordWithCode: function() {
      const errors = this.getFormErrors;
      return (this.email.length > 0 && this.code.length > 0 && errors.length == 0);
    },
  },
  mounted: function() {
    this.validator = this.$refs.formValidator;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/lbd/mixins/_layout.scss";
@import "@/assets/sass/custom/_app-vars.scss";

.login-logo {
  width: 150px;
}

.grid {
  display: grid;
  height: fit-content;
}

.self-end {
  justify-self: end;
}

.layout-button {
  @include respond-to(sm) {
    width: 100% !important;
  }
  @include respond-to(xs) {
    width: 100% !important;
  }
}

.microsoft-sign {
  margin-top: 20px;
  cursor: pointer;
}

.eye-icon:hover {
  color: $primary-border-color;
}

.is-valid {
  color: #2dce89;
  font-weight: 600
}

.is-valid:before {
  width: 100%;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
